import "./style.css";

import Bio from "./Bio";
import Works from "./Works";

export default function App() {
  return (
    <div className="App">
      <Bio />

      <div className="projects-title">Projects</div>
      <Works />
    </div>
  );
}
