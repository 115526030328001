import Social from "./Social";

const Bio = () => {
  return (
    <div className="bio-container">
      {/* <div className="bio-photo">
        <img src="./main-photo.png" alt="bio-photo" />
      </div> */}
      <div className="bio-text">
        <p className="bio-name">Volodymyr Kondretskyi</p>
        <p className="bio-stack"> Front-End Developer / React.js</p>
      </div>
      <div className="links-items">
        <Social />

        <a
          className="bio-download"
          target="_blank"
          rel="noopener noreferrer"
          href="/resume.pdf"
          download="V.Kondretskyi RESUME Front-End"
        >
          Download my Resume
        </a>
      </div>
    </div>
  );
};

export default Bio;
