import React from "react";

// Import the images
import RomeWarriorImage from "./assets/Rome-Warrior.png";
import MessangerImage from "./assets/Messanger.png";
import MarvelImage from "./assets/Marvel.png";
import RealEstateImage from "./assets/Real-Estate.png";
import FoodImage from "./assets/Food.png";
import AccountApp from './assets/AccountApp.png';
import MusicApp from './assets/LetMusic.png';

const Works = () => {
  const projects = [
    {
      link: "https://vkshow.github.io/Music_App/",
      img: MusicApp,
      title: "Music Player",
      stack:
        "ReactJS; Music API; Material-UI; SCSS; Responsive Design; Git; GitHub;",
      descr:
        "This app acts as a music player with features like track selection, track search, play/pause functionality, pick a timeline, and a visually appealing, mobile-friendly interface. It interacts with a local music database and is styled for a smooth user experience.",
    },
    {
      link: "https://vkshow.github.io/jump-game/",
      img: RomeWarriorImage,
      title: "Jump Game",
      stack:
        "ReactJS; React hooks: useState, useEffect, useRef; CSS; Event Handling; Git; GitHub;",
      descr:
        "The game involves a character jumping to avoid enemies, with features like starting/stopping the game, updating positions, handling collisions, and displaying a timer.",
    },
    {
      link: "https://vkshow.github.io/ComIT-messenger/",
      img: MessangerImage,
      title: "ComIT Messenger",
      stack: "ReactJS; React Hooks; JSX; Firestore; Firebase Auth; CSS; Event Handling; Git; GitHub; ",
      descr:
        "An interactive React-based messenger app with real-time chat, powered by Firebase. It showcases efficient data handling, synchronization, and user-friendly design.",
    },
    {
      link: "https://vkshow.github.io/Marvel_Info-Portal/",
      img: MarvelImage,
      title: "Marvel Portal",
      stack: "ReactJS; React classes; React Hooks; RESTful API; JSON; SCSS",
      descr:
        "Explore the Marvel Universe with my Info Portal. Discover detailed profiles of characters, their appearances in comics, and more. Enjoy features like ‘Load More’ for endless content, ‘Random Load’ for surprises, and seamless state handling for a smooth user experience.",
    },
    {
      link: "https://vkshow.github.io/RealEstate/",
      img: RealEstateImage,
      title: "Real-Estate website",
      stack: "HTML, CSS, jQuery, JavaScript; Git; GitHub;",
      descr:
        "Explore our real estate website! With dynamic sliders, find properties that fit your needs. Buy, sell, or rent with ease. Start your property journey today!",
    },
    {
      link: "https://vkshow.github.io/fitnessfood/",
      img: FoodImage,
      title: "Fitness Food website",
      stack: "HTML, CSS, JavaScript; Git; GitHub;",
      descr:
        "Explore my fitness food website, featuring tab navigation, a dynamic slider, and modal window. Use our built-in calculator for tracking nutrition and timer for promotions. Share your experiences via feedback form. Your one-stop solution for fitness and nutrition.",
    },
    {
      link: "https://vkshow.github.io/Accounting/",
      img: AccountApp,
      title: "Accounting App",
      stack: "ReactJS; React Hooks; JSX; Bootstrap; CSS; Event Handling; Git; GitHub;",
      descr:
        "user-friendly React app designed for efficient employee management. Users can add or delete employees, assign bonuses, and utilize custom filters for seamless organization. The intuitive interface and practical functionality demonstrate technical proficiency and an understanding of real-world business needs in team management.",
    },
  ];

  return (
    <>
      {projects.map((project, index) => (
        <a
          key={index}
          className="works-container"
          href={project.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="works-img">
            <img src={project.img} alt="works-img" />
          </div>
          <div className="works-text">
            <div className="works-title">{project.title}</div>
            <div className="works-stack">{project.stack}</div>
            <div className="works-descr">{project.descr}</div>
          </div>
        </a>
      ))}
    </>
  );
};

export default Works;
