import linkedinImage from "./assets/linkedin.png";
import mailImage from "./assets/mail.png";
import githubImage from "./assets/github.png";


const Social = () => {
    return (
      <div className="social">
        <a
        rel="noreferrer"
          href="https://www.linkedin.com/in/vkshow/"
          className="link"
          target="_blank"
        >
          <img src={linkedinImage} alt="linkedIn" />
        </a>
  
        <a rel="noreferrer" href="mailto:justvk2013@gmail.com" className="link" target="_blank">
          <img src={mailImage} alt="email" />
        </a>
  
        <a rel="noreferrer" href="https://github.com/VKshow" className="link" target="_blank">
          <img src={githubImage} alt="github" />
        </a>
      </div>
    );
  };
  
  export default Social;
  